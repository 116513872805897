<template>
  <a href="https://www.askdoctors.jp/summary/adult-adhd" target="_blank">
    <common-button color="white" background-color="green">
      <div class="link-title">
        ADHDの症状・相談先・日常生活の工夫を解説
      </div>
      <div class="link-text">
        大人のADHD特設ページ
      </div>
    </common-button>
  </a>
</template>

<script>
import CommonButton from "@/components/adhd/CommonButton";
export default {
  name: "AdhdSpecialPageLinkButton",
  components: { CommonButton }
};
</script>

<style scoped>
button .link-title {
  font-size: 13px;
  font-weight: 500;
}
button .link-text {
  font-size: 18px;
  font-weight: 700;
}
</style>
