<template>
  <div class="may-adhd-box">
    <hr />
    <a class="box-link" href="https://www.askdoctors.jp/articles/201796" target="_blank">
      <div class="display-flex flex-justify-center case">
        <div class="img">
          <img alt="logo" src="@/assets/adhd/articles_201796.png" />
        </div>
        <div class="text">
          <div class="title">
            「ADHDかもしれない」と思ったら
          </div>
          <div class="description">
            ADHDを疑ったときの相談先や、診断後の治療や支援について解説します。
          </div>
        </div>
        <div class="chevron-right">
          <v-icon color="#28C8BE">chevron_right</v-icon>
        </div>
      </div>
    </a>
    <hr />
    <a class="box-link" href="https://www.askdoctors.jp/articles/201817" target="_blank">
      <div class="display-flex flex-justify-center case">
        <div class="img">
          <img alt="logo" src="@/assets/adhd/articles_201814.png" />
        </div>
        <div class="text">
          <div class="title">
            精神科医からのメッセージ
          </div>
          <div class="description">
            ADHDの方に、医療機関がお手伝いできることについて専門医に伺いました。
          </div>
        </div>
        <div class="chevron-right">
          <v-icon color="#28C8BE">chevron_right</v-icon>
        </div>
      </div>
    </a>
    <hr />
  </div>
</template>

<script>
export default {
  name: "MayAdhdBox"
};
</script>

<style scoped>
.may-adhd-box hr {
  margin: 0px;
}
.may-adhd-box .case {
  margin: 0px;
  padding: 16px 0px;
}
.may-adhd-box .case .img {
  width: 100px;
  height: 100px;
  background-color: #dfdfdf;
  margin: 0px;
  border-radius: 8px;
}

.may-adhd-box .case .text {
  flex: 1;
  margin: 0px 16px;
}
.may-adhd-box .case .text .title {
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  margin: 0px;
  line-height: 21px;
}
.may-adhd-box .case .text .description {
  padding-top: 8px;
  font-size: 13px;
  text-align: left;
}
.may-adhd-box .case .chevron-right {
  margin: auto;
}
.box-link {
  text-decoration: none;
  color: #646E69;
}

@media (hover: hover) {
  .may-adhd-box .case:hover {
    background-color: #f0fafa;
  }
}
@media (hover: none) {
  .may-adhd-box .case:active {
    background-color: #f0fafa;
  }
}
</style>
