<template>
  <div>
    <div class="display-flex flex-justify-center doctor">
      <div class="text">
        <slot />
      </div>
      <div class="img">
        <img
          alt="logo"
          src="@/assets/adhd/result-doctor-icon.png"
          width="96px"
          height="96px"
        />
      </div>
    </div>
    <hr class="doctor-underline" />
  </div>
</template>

<script>
export default {
  name: "DoctorFlexBox"
};
</script>

<style scoped>
.doctor .text {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin: auto;
  flex: 1;
}
.doctor .img {
  margin-left: 8px;
  height: 96px;
}
.doctor-underline {
  border-top: 1px solid #f0f3f3;
  margin: 0px;
}
</style>
