<template>
  <div class="experience">
    <div class="contents">
      <div class="title">
        ADHD患者さんの診断から治療まで<br />
        体験談を公開
      </div>
      <div class="head">
        <div class="display-flex">
          <div class="img">
            <img alt="logo" src="@/assets/adhd/experience-icon01.png" />
          </div>
          <div class="text">
            <div class="title">
              体験談１
            </div>
            <div class="description">
              Aさんの場合（40代・女性 ）
            </div>
          </div>
        </div>
        <div class="body">
          <div class="body-box">
            <div class="text">
              友人から「私が通っている病院がいいよ」とすすめられたので、まずはカウンセリングを受けてみようと思いその病院を受診すると、ADHDと診断されました。
            </div>
            <div class="more-button">
              <a href="https://www.askdoctors.jp/articles/201803" target="_blank">
                <common-button color="green">
                  続きを読む
                </common-button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="head">
        <div class="display-flex flex-reverse flex-justify-space-between">
          <div class="img">
            <img alt="logo" src="@/assets/adhd/experience-icon02.png" />
          </div>
          <div class="text">
            <div class="title">
              体験談２
            </div>
            <div class="description">
              Bさんの場合（40代・女性 ）
            </div>
          </div>
        </div>
        <div class="body">
          <div class="body-box">
            <div class="text">
              事務の仕事をしているのですが、数字や細かいことがとても苦手で、物事に優先順位がつけられない、時間を守れず遅刻してしまうなどで、色々と悩んでいました。
            </div>
            <div class="more-button">
              <a href="https://www.askdoctors.jp/articles/201804" target="_blank">
                <common-button color="green">
                  続きを読む
                </common-button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="head">
        <div class="display-flex">
          <div class="img">
            <img alt="logo" src="@/assets/adhd/experience-icon03.png" />
          </div>
          <div class="text">
            <div class="title">
              体験談３
            </div>
            <div class="description">
              Cさんの場合（30代・女性 ）
            </div>
          </div>
        </div>
        <div class="body">
          <div class="body-box">
            <div class="text">
              子どものADHDの診断を機に、自分にもADHDの特性で当てはまることが多いことが気になり、精神科の先生に相談したところ、ADHDの診断を受けました。
            </div>
            <div class="more-button">
              <a href="https://www.askdoctors.jp/articles/201805" target="_blank">
                <common-button color="green">
                  続きを読む
                </common-button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonButton from "@/components/adhd/CommonButton";
export default {
  name: "AdhdExperienceBox",
  components: { CommonButton }
};
</script>

<style scoped>
.experience {
  margin: 40px 0px;
  background-color: #f5f5f5;
}
.experience .contents {
  margin: 0px 16px;
  padding: 40px 0px;
}
.experience .contents .title {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  line-height: 28px;
  margin: 0px;
}
.experience .contents .head {
  padding-top: 32px;
}

.experience .contents .head .display-flex .img {
  width: 75px;
  height: 75px;
  margin: 0px 8px;
}

.experience .contents .head .display-flex .text {
  text-align: left;
  font-weight: 700;
  margin: auto 8px;
}

.experience .contents .head .display-flex .text .title {
  font-size: 14px;
  margin-bottom: 0px;
}

.experience .contents .head .display-flex .text .description {
  font-size: 16px;
}
.experience .contents .body {
  background: #ffffff;
  border-radius: 8px;
}
.experience .contents .body .body-box {
  padding: 24px;
}
.experience .contents .body .body-box .text {
  text-align: left;
  font-size: 15px;
  line-height: 24px;
}
.experience .contents .body .more-button {
  padding-top: 24px;
}
</style>
